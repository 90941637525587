// @flow

import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InputGroup, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ChevronUp, ChevronDown } from '../../components/Icons';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import ConfigCustomCheckbox from 'components/ConfigCustomCheckbox';
import { Switch } from 'antd';
import CustomSlider from 'components/CustomSlider';

import './styles.scss';
// Selectors
import { selectConfigRecords } from 'services/Config/selectors';
import { setLpmCopy } from 'services/Config/thunks';

function LowPowerModeSettings(props) {
    const [detailed, setDetailed] = useState(true);
    const [buttonChecked, setButtonChecked] = useState(false);

    useEffect(
        () => {
            if (props.checked) {
                setButtonChecked(true);
                props.setLpmCopy(true);
            } else {
                setButtonChecked(false);
                props.setLpmCopy(false);
            }
        },
        [props.checked]
    );
    const handleChange = async (check) => {
        props.setLpmCheck(check);
        await props.handleChange(getChangedItems());
    };

    const getChangedItems = () => {
        return [props.lpmCheck, props.wakeUpIntervalMinutes];
    };
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            props.setLpmCopy(true);
            setButtonChecked(true);
        } else {
            props.setLpmCopy(false);
            setButtonChecked(false);
        }
    };
    return (
        <Grid
            container
            sx={{
                border: 'solid 1px #B6C2E2',
                alignContent: 'flex-start',
                boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.2)',
                padding: '20px',
                height: 'fit-content',
                borderRadius: '7px',
            }}
        >
            <Grid item xs={8}>
                {props.step === 1 && (
                    <ConfigCustomCheckbox
                        checked={buttonChecked}
                        onChange={(e) => handleCheckboxChange(e)}
                    />
                )}
                <span className="economieHeader">
                    <FormattedMessage id={'components.Modals.Form.Config.LPM'} />
                </span>
            </Grid>
            <Grid xs={4} sx={{ display: 'flex', justifyContent: 'end', padding: '2px 10px 0 0' }}>
                {detailed ? (
                    <div onClick={() => setDetailed(false)}>
                        <ChevronUp />
                    </div>
                ) : (
                    <div onClick={() => setDetailed(true)}>
                        <ChevronDown />
                    </div>
                )}
            </Grid>
            <Grid item xs={12} sx={{ mb: '15px' }}>
                <hr style={{ border: 'none', height: '1px', backgroundColor: '#B6C2E2' }} />
            </Grid>
            {detailed ? (
                <Grid item container sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Grid item xs={1}>
                        <Switch onChange={handleChange} checked={props.lpmCheck} />
                    </Grid>
                    <Grid item xs={4}>
                        <FormattedMessage id={'components.Modals.Form.Config.LPM.freq'} />
                    </Grid>

                    {props.lpmCheck ? (
                        <Grid item xs={8} style={{ height: 50, marginTop: '15px' }}>
                            <CustomSlider
                                mode={1}
                                step={5}
                                values={[props.wakeUpIntervalMinutes]}
                                domain={[15, 450]}
                                onUpdate={async (e) => {
                                    await props.setWakeUpIntervalMinutes(e[0]).toFixed(2);
                                }}
                            />
                        </Grid>
                    ) : null}
                </Grid>
            ) : (
                ''
            )}
        </Grid>
    );
}

// MAP STATE/DISPATCH
const mapStateToProps = createStructuredSelector({
    configRecords: selectConfigRecords(),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setLpmCopy }, dispatch);

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(LowPowerModeSettings));
