// @flow

import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import CustomSlider from 'components/CustomSlider';
import { ChevronUp, ChevronDown } from '../../components/Icons';
import ConfigCustomCheckbox from 'components/ConfigCustomCheckbox';
import { setAccelerometreCopy } from 'services/Config/thunks';
// Selectors
import { selectConfigRecords, selectModuleRecords } from 'services/Config/selectors';

type Props = {
    configRecords: *,
    moduleRecords: *,
    handleChange: *,
};

const AccelerometreSettings = (props: Props) => {
    const [detailed, setDetailed] = useState(true);
    const [buttonChecked, setButtonChecked] = useState(false);
    const [minorVersion, setMinorVersion] = useState(
        props.moduleRecords.getIn(['version', 'minor'])
    );
    const [buildVersion, setBuildVersion] = useState(
        props.moduleRecords.getIn(['version', 'build'])
    );
    

    // const getChangedItems = () => {
    //     return [gForceAccelerationDecelerationThreshold, impact, seriesBar];
    // };

    const isAccelCalibration = (minor, build) => {
        if (minor == 205 && build < 20) {
            return false;
        }
        if (minor < 205) {
            return false;
        }
        return true;
    };
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            props.setAccelerometreCopy(true);
            setButtonChecked(true);
        } else {
            props.setAccelerometreCopy(false);
            setButtonChecked(false);
        }
    };
    const isAccelCalibrated = isAccelCalibration(minorVersion, buildVersion);
    useEffect(
        () => {
            if (props.checked) {
                setButtonChecked(true);
                props.setAccelerometreCopy(true);
            } else {
                setButtonChecked(false);
                props.setAccelerometreCopy(false);
            }
        },
        [props.checked]
    );
    useEffect(
        () => {
            if (props.step === 1) {
                setButtonChecked(true);
                props.setAccelerometreCopy(true);
            } else {
                setButtonChecked(false);
                props.setAccelerometreCopy(false);
            }
        },
        [props.step]
    );
    return (
        <Grid
            container
            sx={{
                border: 'solid 1px #B6C2E2',
                alignContent: 'flex-start',
                boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.2)',
                padding: '30px',
                height: detailed ? '48vh' : 'fit-content',
                overflowY: 'scroll',
                borderRadius: '7px',
            }}
        >
            <Grid xs={8}>
                {props.step === 1 && (
                    <ConfigCustomCheckbox
                        checked={buttonChecked}
                        onChange={(e) => handleCheckboxChange(e)}
                    />
                )}
                <span className="economieHeader">
                    <FormattedMessage id={'components.Modals.Form.Config.Accelerometre'} />
                </span>
            </Grid>
            <Grid xs={4} sx={{ display: 'flex', justifyContent: 'end', padding: '2px 10px 0 0' }}>
                {detailed ? (
                    <div onClick={() => setDetailed(false)}>
                        <ChevronUp />
                    </div>
                ) : (
                    <div onClick={() => setDetailed(true)}>
                        <ChevronDown />
                    </div>
                )}
            </Grid>
            <Grid item xs={12} sx={{ mb: '15px' }}>
                <hr style={{ border: 'none', height: '1px', backgroundColor: '#B6C2E2' }} />
            </Grid>

            {detailed ? (
                <Grid item xs={12} container>
                    {isAccelCalibrated ? (
                        <Grid container item xs={12}>
                            <Grid item xs={12} style={{ paddingTop: 10, height: 50 }}>
                                <FormattedMessage
                                    id={'components.Modals.Form.Config.Accelerometre.avant'}
                                />
                            </Grid>
                                <Grid item xs={4} style={{ paddingLeft:'30px', height: 50}}>
                                    <FormattedMessage
                                        id={'components.Modals.Form.Config.Accelerometre.Accel'}
                                    />
                                </Grid>
                                <Grid item xs={8} style={{ height: 50 }}>
                                    <CustomSlider
                                        mode={1}
                                        step={0.05}
                                        domain={[0, 4]}
                                        values={[props.seriesBar[0].data[1]]}
                                        onUpdate={async (e) => {
                                            const newBarSeries = [];
                                            props.seriesBar.map((s, index) => {
                                                if (index == 0) {
                                                    newBarSeries.push({
                                                        data: [
                                                            props.seriesBar[0].data[0],
                                                            Number(e[0]).toFixed(2),
                                                            props.seriesBar[0].data[2],
                                                            props.seriesBar[0].data[3],
                                                        ],
                                                        name: s.name,
                                                    });
                                                } else {
                                                    newBarSeries.push({
                                                        data: s.data,
                                                        name: s.name,
                                                    });
                                                }
                                            });
                                            props.setSeriesBar(newBarSeries);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4} style={{ paddingLeft:'30px', height: 50 }}>
                                    <FormattedMessage
                                        id={'components.Modals.Form.Config.Accelerometre.Decel'}
                                    />
                                </Grid>
                                <Grid item xs={8} style={{ height: 50 }}>
                                    <CustomSlider
                                        mode={1}
                                        step={0.05}
                                        values={[props.seriesBar[1].data[1]]}
                                        domain={[0, 4]}
                                        onUpdate={async (e) => {
                                            const newBarSeries = [];
                                            props.seriesBar.map((s, index) => {
                                                if (index == 1) {
                                                    newBarSeries.push({
                                                        data: [
                                                            props.seriesBar[0].data[0],
                                                            Number(e[0]).toFixed(2),
                                                            props.seriesBar[0].data[2],
                                                            props.seriesBar[1].data[3],
                                                        ],
                                                        name: s.name,
                                                    });
                                                } else {
                                                    newBarSeries.push({
                                                        data: s.data,
                                                        name: s.name,
                                                    });
                                                }
                                            });
                                            props.setSeriesBar(newBarSeries);
                                        }}
                                    />
                                </Grid>
                            <Grid item xs={12} style={{ height: 50 }}>
                                <FormattedMessage
                                    id={'components.Modals.Form.Config.Accelerometre.arriere'}
                                />
                            </Grid>
                                <Grid item xs={4} style={{ paddingLeft:'30px',height: 50 }}>
                                    <FormattedMessage
                                        id={'components.Modals.Form.Config.Accelerometre.Accel'}
                                    />
                                </Grid>
                                <Grid item xs={8} style={{ height: 50 }}>
                                    <CustomSlider
                                        mode={1}
                                        step={0.05}
                                        values={[props.seriesBar[0].data[3]]}
                                        domain={[0, 4]}
                                        onUpdate={async (e) => {
                                            const newBarSeries = [];
                                            props.seriesBar.map((s, index) => {
                                                if (index == 0) {
                                                    newBarSeries.push({
                                                        data: [
                                                            props.seriesBar[0].data[0],
                                                            props.seriesBar[0].data[1],
                                                            props.seriesBar[0].data[2],
                                                            Number(e[0]).toFixed(2),
                                                        ],
                                                        name: s.name,
                                                    });
                                                } else {
                                                    newBarSeries.push({
                                                        data: s.data,
                                                        name: s.name,
                                                    });
                                                }
                                            });
                                            props.setSeriesBar(newBarSeries);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4} style={{ paddingLeft:'30px',height: 50 }}>
                                    <FormattedMessage
                                        id={'components.Modals.Form.Config.Accelerometre.Decel'}
                                    />
                                </Grid>
                                <Grid item xs={8} style={{ height: 50 }}>
                                    <CustomSlider
                                        mode={1}
                                        step={0.05}
                                        values={[props.seriesBar[1].data[3]]}
                                        domain={[0, 4]}
                                        onUpdate={async (e) => {
                                            const newBarSeries = [];
                                            props.seriesBar.map((s, index) => {
                                                if (index == 1) {
                                                    newBarSeries.push({
                                                        data: [
                                                            props.seriesBar[0].data[0],
                                                            props.seriesBar[1].data[1],
                                                            props.seriesBar[0].data[2],
                                                            Number(e[0]).toFixed(2),
                                                        ],
                                                        name: s.name,
                                                    });
                                                } else {
                                                    newBarSeries.push({
                                                        data: s.data,
                                                        name: s.name,
                                                    });
                                                }
                                            });
                                            props.setSeriesBar(newBarSeries);
                                        }}
                                    />
                                </Grid>
                            <Grid item xs={12} container sx={{ height: 50 }}>
                                <Grid item xs={4} style={{ height: 50 }}>
                                    <FormattedMessage
                                        id={'components.Modals.Form.Config.Accelerometre.laterale'}
                                    />
                                </Grid>
                                <Grid item xs={8} style={{ height: 50 }}>
                                    <CustomSlider
                                        mode={1}
                                        step={0.05}
                                        values={[props.seriesBar[0].data[0]]}
                                        domain={[0, 4]}
                                        onUpdate={async (e) => {
                                            const newBarSeries = [];
                                            props.seriesBar.map((s, index) => {
                                                if (index == 0) {
                                                    newBarSeries.push({
                                                        data: [
                                                            Number(e[0]).toFixed(2),
                                                            props.seriesBar[0].data[1],
                                                            Number(e[0]).toFixed(2),
                                                            props.seriesBar[0].data[3],
                                                        ],
                                                        name: s.name,
                                                    });
                                                } else if (index == 1) {
                                                    newBarSeries.push({
                                                        data: [
                                                            Number(e[0]).toFixed(2),
                                                            props.seriesBar[1].data[1],
                                                            Number(e[0]).toFixed(2),
                                                            props.seriesBar[1].data[3],
                                                        ],
                                                        name: s.name,
                                                    });
                                                } else {
                                                    newBarSeries.push({
                                                        data: s.data,
                                                        name: s.name,
                                                    });
                                                }
                                            });
                                            props.setSeriesBar(newBarSeries);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12} container sx={{ height: 50 }}>
                            <Grid item xs={4} style={{ height: 50 }}>
                                <FormattedMessage
                                    id={'components.Modals.Form.Config.Accelerometre.AccelDecel'}
                                />
                            </Grid>
                            <Grid item xs={8} style={{ height: 50 }}>
                                <CustomSlider
                                    mode={1}
                                    step={0.05}
                                    values={[props.gForceAccelerationDecelerationThreshold]}
                                    domain={[0, 4]}
                                    onUpdate={async (e) => {
                                        await props.setGForceAccelerationDecelerationThreshold(
                                            e[0]
                                        ).toFixed(2);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12} container sx={{ height: 50 }}>
                        <Grid item xs={4} style={{ height: 50 }}>
                            <FormattedMessage
                                id={'components.Modals.Form.Config.Accelerometre.Impact'}
                            />
                        </Grid>
                        <Grid item xs={8} style={{ height: 50 }}>
                            <CustomSlider
                                mode={1}
                                step={0.05}
                                values={[props.impact]}
                                domain={[0, 4]}
                                onUpdate={async (e) => {
                                    await props.setImpact(e[0]).toFixed(
                                        2
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                ''
            )}
        </Grid>
    );
};

// MAP STATE/DISPATCH
const mapStateToProps = createStructuredSelector({
    configRecords: selectConfigRecords(),
    moduleRecords: selectModuleRecords(),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setAccelerometreCopy }, dispatch);

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(AccelerometreSettings));
