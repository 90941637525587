// @flow

import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Organization from './styles';
import dateFns from 'date-fns';
import moment from 'moment';
import { Tooltip } from 'antd';
import Chip from '../Chip';
import TestOrganizationImage from 'assets/test-organization.png';
import { generateIsRouteProPerso, openMoveReportModal, setMoveInfo } from 'services/Report/thunks';
import type { ColumnType } from 'types';
import ToggleProPersoStyle from '../ToggleProPerso/styles';

import { isImperial } from 'services/Units/thunks';
//Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { selectMoveModal } from 'services/Report/selectors';
type Props = {
    rowSpan: ?number,
    data: Object<any>,
    columns: Array<ColumnType>,
    showStopTime: *,
};

class OrganizationListItem extends React.PureComponent<Props> {
    constructor(props) {
        super(props);
        this.toggleRef = React.createRef(); // Create a ref for the toggle
    }
    state = {
        isProJourney: this.props.data.get('isPrivateMode'),
    };

    handleIsJourneyPro = (e, data) => {
        if (this.props.onToggleChange) {
            this.props.onToggleChange(e, data);
            this.props.generateIsRouteProPerso(this.props.data.get('reportId'));
        } else {
            console.error('onToggleChange is not a function');
        }
    };
    dateFormat = (datestart, dateend) => {
        const text = this.props.intl.formatMessage({ id: 'containers.Reports.Generators.move.To' });
        let dayOfWeek = dateFns.format(datestart, 'dddd');
        let nextDayOfWeek;
        if (dayOfWeek === 'Monday') {
            dayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Monday',
            });
            nextDayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Tuesday',
            });
        } else if (dayOfWeek === 'Tuesday') {
            dayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Tuesday',
            });
            nextDayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Wednesday',
            });
        } else if (dayOfWeek === 'Wednesday') {
            dayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Wednesday',
            });
            nextDayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Thursday',
            });
        } else if (dayOfWeek === 'Thursday') {
            dayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Thursday',
            });
            nextDayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Friday',
            });
        } else if (dayOfWeek === 'Friday') {
            dayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Friday',
            });
            nextDayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Saturday',
            });
        } else if (dayOfWeek === 'Saturday') {
            dayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Saturday',
            });
            nextDayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Sunday',
            });
        } else if (dayOfWeek === 'Sunday') {
            dayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Sunday',
            });
            nextDayOfWeek = this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.move.Monday',
            });
        }

        if (dateFns.format(datestart, 'YYYY/MM/DD') === dateFns.format(dateend, 'YYYY/MM/DD')) {
            return `${dayOfWeek} ${dateFns.format(datestart, 'YYYY/MM/DD')} ${dateFns.format(
                datestart,
                'h:mm A'
            )}
            ${text} ${dateFns.format(dateend, 'h:mm A')}`;
        }
        return `${dayOfWeek} ${moment(datestart).format('YYYY/MM/DD - h:mm A')} ${text} ${nextDayOfWeek} ${moment(dateend).format(
            'YYYY/MM/DD - h:mm A'
        )}`;
    };

    hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    };

    intToARGB = (i) => {
        let hex =
            ((i >> 24) & 0xff).toString(16) +
            ((i >> 16) & 0xff).toString(16) +
            ((i >> 8) & 0xff).toString(16) +
            (i & 0xff).toString(16);
        hex += '000000';
        return hex.substring(0, 6);
    };

    convertStringToColor = (str) => {
        if (!str) return '';
        str = str.replace(/\s+/g, '');
        return `#${this.intToARGB(this.hashCode(str))}`;
    };

    hexToHSL = (H) => {
        // Convert hex to RGB first
        let r = 0;
        let g = 0;
        let b = 0;
        if (H.length == 4) {
            r = `0x${H[1]}${H[1]}`;
            g = `0x${H[2]}${H[2]}`;
            b = `0x${H[3]}${H[3]}`;
        } else if (H.length == 7) {
            r = `0x${H[1]}${H[2]}`;
            g = `0x${H[3]}${H[4]}`;
            b = `0x${H[5]}${H[6]}`;
        }
        // Then to HSL
        r /= 100;
        g /= 280;
        b /= 100;
        const cmin = Math.min(r, g, b);
        const cmax = Math.max(r, g, b);
        const delta = cmax - cmin;
        let h = 0;
        if (delta == 0) h = 0;
        else if (cmax == r) h = ((g - b) / delta) % 6;
        else if (cmax == g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;
        h = Math.round(h * 60);
        if (h < 0) h += 360;

        return `hsl(${h},${80}%,${60}%)`;
    };

    handleOpenMoveModal = () => {
        let startTimeArray = this.props.data.get('startTime').split(' ');
        let endTimeArray = this.props.data.get('endTime').split(' ');
        let periodDateFrom = startTimeArray[0];
        let periodDateTo = endTimeArray[0];
        let periodTimeFrom = `${startTimeArray[1].split(':')[0]}:${
            startTimeArray[1].split(':')[1]
        }`;
        let periodTimeTo = `${endTimeArray[1].split(':')[0]}:${endTimeArray[1].split(':')[1]}`;
        let driverName = this.props.data.get('driverName');
        let distance = this.props.data.get('totalDistance');
        let stopAddress = this.props.data.get('stopAddress');
        let startAddress = this.props.data.get('startAddress');
        let driverChipColor = this.hexToHSL(
            this.convertStringToColor(this.props.data.get('driverName'))
        );
        this.props.setMoveInfo(
            [`${this.props.vehicleId}`],
            this.props.vehiculeNumb,
            distance,
            periodDateFrom,
            periodDateTo,
            periodTimeFrom,
            periodTimeTo,
            driverName,
            driverChipColor,
            stopAddress,
            startAddress,
            this.props.isPrivateMode,
            this.props.data.get('reportId'),
            this.toggleRef
        );
        this.props.openMoveReportModal();
    };

    render() {
        let hasGasConsumption = false;
        const keys = this.props.data.keySeq();

// If you want it as an array
const keysArray = keys.toArray();
console.log('keysArray', keysArray);
        let rowSpan = 8;
        let showExtraRows = false;
        if (this.props.data.get('transitTime') != -1) {
            rowSpan = 9;
            showExtraRows = true;
        }
        if (this.props.tableFooter.get('totalVehicleGasConsumption') != 0) {
            hasGasConsumption = true;
            rowSpan = 9;
        }

        let stopAddress = this.props.data.get('stopAddress');
        let displayAddress;

        let GeofencesNames = [];
        // let stopGeofencesNames = '';
        try {
            let dataGeofences = this.props.data.toJS();
            const startGeofences = dataGeofences.startGeofences;
            const stopGeofences = dataGeofences.stopGefences;
            if (startGeofences.length > 0) {
                startGeofences.forEach((geofence) => {
                    GeofencesNames.push(geofence.geoName);
                });
            }
            if (stopGeofences.length > 0) {
                stopGeofences.forEach((geofence) => {
                    GeofencesNames.push(geofence.geoName);
                });
            }
        } catch (e) {
            console.log(e);
        }

        let UniqueGeofencesNames = [...new Set(GeofencesNames)].join(', ');

        // if(UniqueGeofencesNames.length==0){
        //     UniqueGeofencesNames = "Configuration globale";
        // }

        // console.log("startGeofencesNames",startGeofencesNames);
        // console.log("stopGeofencesNames",stopGeofencesNames);
        // console.log("StartStopGeofencesNames",StartStopGeofencesNames);

        if (stopAddress) {
            const addressParts = stopAddress.split(',').map((part) => part.trim());
            if (addressParts.length === 4 && addressParts[1] === 'Churchill Falls') {
                displayAddress = `${addressParts[2]}, ${addressParts[3]}`;
            } else {
                displayAddress = stopAddress;
            }
        }
        let startAddress = this.props.data.get('startAddress');
        let displayStartAddress;

        if (startAddress) {
            const addressParts = startAddress.split(',').map((part) => part.trim());
            if (addressParts.length === 4 && addressParts[1] === 'Churchill Falls') {
                displayStartAddress = `${addressParts[2]}, ${addressParts[3]}`;
            } else {
                displayStartAddress = startAddress;
            }
        }
        return (
            <Organization>
                <Organization.Griditem rowSpan={rowSpan}>
                    <Tooltip
                        placement="bottom"
                        title={this.props.intl.formatMessage({
                            id: 'Components.Reports.Move.HoverMessage',
                        })}
                    >
                        <Organization.datetime key="datetime" onClick={this.handleOpenMoveModal}>
                            {this.dateFormat(
                                this.props.data.get('startTime'),
                                this.props.data.get('endTime')
                            )}
                        </Organization.datetime>
                    </Tooltip>
                    <Organization.utilisationtime key="utilisationTime">
                        {this.props.data.get('utilisationTime')}
                    </Organization.utilisationtime>

                    <Organization.idletime key="totalTimeIdle">
                        {this.props.data.get('totalTimeIdle')}
                    </Organization.idletime>
                    {displayStartAddress ? (
                        <Organization.stopaddress key="startAddress">
                            <i className="fas fa-map-marker-alt" style={{ color: '#9E9E9E' }} />
                            &nbsp;
                            {displayStartAddress}
                        </Organization.stopaddress>
                    ) : (
                        <empty />
                    )}
                    <Organization.totaldistance key="totalDistance">
                        <Chip type="distance"> {this.props.data.get('totalDistance')}</Chip>
                        &nbsp;&nbsp;
                        {this.props.data.get('driverName') ? (
                            <Chip
                                type="driver"
                                color={this.hexToHSL(
                                    this.convertStringToColor(this.props.data.get('driverName'))
                                )}
                            >
                                <i className="far fa-id-badge" />
                                &nbsp;
                                {this.props.data.get('driverName')}
                            </Chip>
                        ) : (
                            <empty />
                        )}
                        &nbsp;&nbsp;
                        {/*{this.props.data.get('isPrivateMode') ? (*/}
                        {/*    <Chip type="personal" />*/}
                        {/*) : (*/}
                        {/*    <empty />*/}
                        {/*)}*/}
                    </Organization.totaldistance>
                    {showExtraRows && (
                        <Organization.totaldistance key="transitTime">
                            {this.props.data.get('transitTime')}
                        </Organization.totaldistance>
                    )}
                    {hasGasConsumption && (
                        <Organization.totaldistance key="gasConsumption">
                            {this.props.data.get('gasConsumption')}
                        </Organization.totaldistance>
                    )}
                    <Organization.isPrivateMode key="isPrivateMode">
                        <FormControlLabel
                            control={
                                <ToggleProPersoStyle
                                    sx={{ m: 1 }}
                                    checked={this.props.isPrivateMode}
                                    onChange={(e) => this.handleIsJourneyPro(e, this.props.data)}
                                />
                            }
                        />
                    </Organization.isPrivateMode>
                    <Organization.odometer key="odometer">
                        {this.props.data.get('odometer')}
                    </Organization.odometer>

                    {UniqueGeofencesNames.length > 0 ? (
                        <Organization.geofenceName key="geofenceName">
                            <i className="fas fa-draw-polygon" />
                            {UniqueGeofencesNames}
                        </Organization.geofenceName>
                    ) : (
                        <Organization.geofenceName key="geofenceName">
                            <i className="fas fa-draw-polygon" />

                            {this.props.intl.formatMessage({
                                id: 'containers.Reports.Generators.NoData',
                            })}
                        </Organization.geofenceName>
                    )}
                </Organization.Griditem>
                <Organization.Griditem rowSpan={rowSpan}>
                    <Organization.empty />
                    <Organization.stoptime key="stopTime">
                        {this.props.showStopTime && this.props.data.get('stopTime')}
                    </Organization.stoptime>
                    <Organization.empty />

                    {displayAddress ? (
                        <Organization.stopaddress key="stopAddress">
                            <i className="fas fa-map-marker-alt" style={{ color: '#F44336' }} />
                            &nbsp;
                            {displayAddress}
                        </Organization.stopaddress>
                    ) : (
                        <empty />
                    )}
                    <Organization.empty />
                    <Organization.empty />
                    <Organization.horometer key="horometer">
                        {this.props.data.get('horometer')}
                    </Organization.horometer>
                    {showExtraRows && (
                        <Organization.totaldistance key="distanceTarget">
                            <Chip type="distance"> {this.props.data.get('distanceTarget')}</Chip>
                        </Organization.totaldistance>
                    )}
                    <Organization.empty />
                </Organization.Griditem>
            </Organization>
        );
    }
}
const mapStateToProps = createStructuredSelector({
    moveReportModal: selectMoveModal(),
});
const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            isImperial,
            openMoveReportModal,
            setMoveInfo,
            generateIsRouteProPerso,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(OrganizationListItem))
);
