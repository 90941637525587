// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Dashboard } from 'styles/dashboard';

import dateFns from 'date-fns';

import type {
    ReduxDispatch,
    ImmutableList,
    ImmutableMap,
    UserType,
    ColumnType,
    PaginatedType,
} from 'types';

import OrganizationListItem from 'components/OrganizationListItemMoveV1';
import DashboardDataComponent from 'components/DashboardDataComponent';
import GroupButton from 'components/GroupButton';
import OutlineButton from 'components/OutlineButton';

import { selectLocale } from 'services/Language/selectors';
import { selectMoveType } from 'services/Report/selectors';

import CaretDown from 'assets/caret-down.png';
import CaretUp from 'assets/caret-up.png';
import Chip from 'components/Chip';
import colors from 'styles/colors';

type SortFunction = ?(a: string | number, b: string | number) => void;

type Props = {
    columns: Array<ColumnType>,
    id: ?string,
    dataKeys: ?Array<string>,
    caretSource: ?string,
    tableData: ?ImmutableList<string>,
    totalItemsCount: ?number,
    tableFooter: ?ImmutableList<string>,
    users: ?boolean,
    list?: boolean,
    onClickDataItem: ?(number) => void,
    onClickViewMore: ?() => void,
    onClickEditUser: ?(user: UserType) => void,
    onClickDeleteUser: ?(user: UserType) => void,
    onClickHeaderItem: ?(key: string, sortMethod: SortFunction) => void,
    locale: string,
    component: ?ReactComponent,
    paginated: ?PaginatedType,
    loading: ?boolean,
    itemsDisplayed?: number,
    showHeaderCaret?: boolean,
    report?: boolean,
    reportFilterText: *,
};

type State = {
    showViewMore: boolean,
    showUserOptions: ?number,
    showEquipmentOptions: ?number,
    sortKey: string,
    toggledIds: Set<number>,
    // sortedData: ?ImmutableList<string>,
};

class DashboardComponent extends React.PureComponent<Props, State> {
    static defaultProps = {
        list: false,
        paginated: null,
        sliceUsers: true,
        itemsDisplayed: 10,
        showHeaderCaret: true,
        report: false,
    };

    state = {
        showViewMore: false,
        showUserOptions: -1,
        showEquipmentOptions: -1,
        sortKey: '',
        toggledIds: new Set(),
        // sortedData: null,
    };

    handleOnClickDataItem = (id: number) => () => {
        this.props.onClickDataItem(id);
    };

    handleShowUserOptions = (user: UserType) => {
        if (this.state.showUserOptions === user.get('userId')) {
            return this.setState({
                showUserOptions: -1,
            });
        }

        this.setState({
            showUserOptions: user.get('userId'),
        });
    };

    handleToggleChange = (e, data) => {
        this.setState((prevState) => {
            const isToggled = prevState.toggledIds.has(data.get('reportId'));
            const updatedToggledIds = new Set(prevState.toggledIds);
            if (isToggled) {
                updatedToggledIds.delete(data.get('reportId'));
            } else {
                updatedToggledIds.add(data.get('reportId'));
            }
            return { toggledIds: updatedToggledIds };
        });
    };


    handleShowEquipmentOptions = (equipment: EquipmentType) => {
        if (this.state.showEquipmentOptions === equipment.get('id')) {
            return this.setState({
                showEquipmentOptions: -1,
            });
        }

        this.setState({
            showEquipmentOptions: equipment.get('id'),
        });
    };

    formatLastLogin = (user) => {
        if (!user.get('lastLogin')) {
            return null;
        }

        return dateFns.format(user.get('lastLogin'), 'DD/MM/YYYY - h:mm A');
    };

    getHeaderCaret = (key: string) => (this.state.sortKey === key ? CaretUp : CaretDown);

    /**
     * Render that shows
     * @param {number} columnSize Decides on how many columns
     * @param {boolean} showExtraColumns show or not total stop time
     * @param {boolean} tableFooter get totalVehicleUtilisationTime && totalVehicleDistance
     * @return {Component} rendered component
     */
    renderFooterItem = (columnSize, showExtraColumns, tableFooter) => {
        let totalVehicleGasConsumption = false
        if (tableFooter.get('totalVehicleGasConsumption') != 0) {
            totalVehicleGasConsumption = true
        }
        return (
            this.props.moveReportSummary ?
            (<Dashboard.SummaryContainer columns={7}>
                <Dashboard.FooterItem key={this.props.columns[0].key}>
                <Dashboard.FooterText>
                    {this.props.totalItemsCount}{' '}
                    <FormattedMessage id={'containers.Reports.Generators.Trajet'}></FormattedMessage>
                </Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                <Dashboard.FooterText>
                    {tableFooter.get('totalVehicleUtilisationTime')}
                    <br />
                    {!showExtraColumns && tableFooter.get('totalVehicleStopTime')}
                </Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                <Dashboard.FooterText>
                    {tableFooter.get('totalVehicleTimeIdle')}
                </Dashboard.FooterText>
                </Dashboard.FooterItem>

                <Dashboard.FooterItem>
                <Chip type="distance">{tableFooter.get('totalVehicleDistance')}</Chip>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                <Dashboard.FooterText>
                    {tableFooter.get('lastOdometer')}
                </Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                <Dashboard.FooterText>
                    {tableFooter.get('lastHorometer')}
                </Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                <Chip type="personal">{tableFooter.get('totalVehicleDistancePerso')}</Chip>
                <Chip type="work">{tableFooter.get('totalVehicleDistanceWork')}</Chip>
                </Dashboard.FooterItem>
            </Dashboard.SummaryContainer>) :
            (<Dashboard.FooterContainer columns={columnSize}>
                <Dashboard.FooterItem key={this.props.columns[0].key}>
                <Dashboard.FooterText>
                    <FormattedMessage id={'containers.Reports.Generators.Total'} />{' '}
                    {this.props.totalItemsCount}{' '}
                    <FormattedMessage id={'containers.Reports.Generators.Trajet'} />{' '}
                </Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                <Dashboard.FooterText>
                    {tableFooter.get('totalVehicleUtilisationTime')}
                    <br />
                    {!showExtraColumns && tableFooter.get('totalVehicleStopTime')}
                </Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                <Dashboard.FooterText>
                    {tableFooter.get('totalVehicleTimeIdle')}
                </Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                <Dashboard.FooterText>

                </Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                <Chip type="distance">{tableFooter.get('totalVehicleDistance')}</Chip>
                </Dashboard.FooterItem>
                {totalVehicleGasConsumption ?
                <Dashboard.FooterItem>
                    <Dashboard.FooterText>
                    {tableFooter.get('totalVehicleGasConsumption')}
                    </Dashboard.FooterText>
                </Dashboard.FooterItem> : null}
                <Dashboard.FooterItem>
                <Chip type="personal">{tableFooter.get('totalVehicleDistancePerso')}</Chip>
                <Chip type="work">{tableFooter.get('totalVehicleDistanceWork')}</Chip>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                <Dashboard.FooterText>
                    {tableFooter.get('lastOdometer')} <br />
                    {tableFooter.get('lastHorometer')}
                </Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                <Dashboard.FooterText>
                </Dashboard.FooterText>
                </Dashboard.FooterItem>
            </Dashboard.FooterContainer>)
        );
    };

    renderTableData = (showStopTime) => {
        let dataItems = this.props.tableData;
        if (!this.props.tableData) {
            return false;
        }

        if (this.state.sortedData && this.state.sortedData.size > 0) {
            dataItems = this.state.sortedData;
        }

        if (
            typeof this.props.onClickViewMore === 'function' &&
            !this.props.list &&
            dataItems &&
            dataItems.size > this.props.itemsDisplayed
        ) {
            // show the first 10 items.
            dataItems = dataItems.take(this.props.itemsDisplayed);
            this.setState({
                showViewMore: true,
            });
        }

        if (this.props.report) {
            dataItems = dataItems.take(this.props.itemsDisplayed);
        }

        if (
            this.props.paginated &&
            this.props.paginated.get('currentPage') !== this.props.paginated.get('lastPage')
        ) {
            this.setState({
                showViewMore: true,
            });
        }
        const listItems = dataItems.map((data: ImmutableMap, index: number) => {

            const isToggled = this.state.toggledIds.has(data.get('reportId'))
            const isPrivateMode = isToggled ? !data.get('isPrivateMode') : data.get('isPrivateMode');

            let componentProps = {
                rowSpan: this.props.columns.length,
                data,
            };

            if (this.props.component) {
                componentProps = {
                    rowSpan: this.props.columns.length,
                    data,
                    ...this.props,
                    showUserOptions: this.state.showUserOptions,
                    showEquipmentOptions: this.state.showEquipmentOptions,
                    handleShowUserOptions: this.handleShowUserOptions,
                    handleShowEquipmentOptions: this.handleShowEquipmentOptions,
                };
            }

            if (!data) {
                return false;
            }

            return (
                <Dashboard.DataItemCustom
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${data.get(this.props.id)}-${index}`}
                    columns={this.props.columns.length}
                    onClick={
                        this.props.onClickDataItem
                            ? this.handleOnClickDataItem(data.get(this.props.id))
                            : null
                    }
                >
                    {this.props.component ? (
                        <DashboardDataComponent
                            {...componentProps}
                            component={this.props.component}
                        />
                    ) : (
                        <OrganizationListItem
                            key={`${data.get(this.props.id)}-${index}`}
                            vehicleId={this.props.vehicleId}
                            vehiculeNumb={this.props.vehiculeNumb}
                            columns={this.props.columns}
                            data={data}
                            showStopTime={!showStopTime}
                            rowSpan={this.props.columns.length}
                            tableFooter={this.props.tableFooter}
                            isPrivateMode={isPrivateMode}
                            onToggleChange={(e) => this.handleToggleChange(e, data)}
                        />
                    )}
                </Dashboard.DataItemCustom>
            );
        });
        return (
            <Dashboard.GridContainerCustom columns={this.props.columns.length}>
                {listItems}
            </Dashboard.GridContainerCustom>
        );
    };

    renderShowMoreUsers = () => {
        return this.props.tableData.size > 0 ? (
            <Dashboard.ViewMore onClick={this.props.onClickViewMore}>
                <OutlineButton outlineColor={colors.green73} hoverTextColor={colors.white}>
                    {this.props.loading ? (
                        <FormattedMessage id={'components.Loading'} />
                    ) : (
                        <FormattedMessage id={'views.BranchPage.ViewMore'} />
                    )}
                </OutlineButton>
            </Dashboard.ViewMore>
        ) : null;
    };

    render() {
        let hasGasComsumtion = false;
        const tmp = this.props.tableData;
        let columnSize = 8;
        let showExtraColumns = false;

        let startTime = 0;
        let endTime = 1;
        let utilisationTime =2;
        let stopTime = 3;
        let totalTimeIdle = 4;
        let distance = 5;
        let driverName = 6;
        let stopAddress = 7;
        let odometer = 9;
        let horometer = 10;
        let isPrivateMode = 8;
        let geofenceName = 11;
        let startAddress = 15;
        let transitTime = -1;
        let distanceTarget = -1;

        if (tmp && tmp.get(0) && tmp.get(0).get('transitTime') != -1) {
            tmp.get(0).get('transitTime');
            columnSize = 8;
            showExtraColumns = true;
            startTime = 1;
            endTime = 2;
            isPrivateMode = 9;
            geofenceName = 12;
            distance = 6;
            utilisationTime = 3;
            totalTimeIdle = 5;
            stopTime = 4;
            stopAddress = 8 ;
            driverName = 7;
            transitTime = 13;
            distanceTarget = 14;
            startAddress = 15;
            horometer = 11;
            odometer = 10;
        }

        if (this.props.tableFooter.get('totalVehicleGasConsumption') != 0) {
            hasGasComsumtion = true
            columnSize = 9;
        }
        return (
            this.props.moveReportSummary ? (
                <Dashboard loading={this.props.loading}>
                    <Dashboard.SummaryHeaderContainer columns={columnSize}>
                        <Dashboard.HeaderItem
                            key={''}
                        >
                            <Dashboard.HeaderText>
                                <FormattedMessage id={'containers.Reports.Generators.Total'}></FormattedMessage>
                                <FormattedMessage id={'containers.Reports.Generators.Trajet'}></FormattedMessage>
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                        <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                                {this.props.columns[utilisationTime].label}
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                        <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                                {this.props.columns[totalTimeIdle].label}
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                        <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                                {this.props.columns[distance].label}
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                        <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                                {this.props.columns[odometer].label}
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                        <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                                {this.props.columns[horometer].label}
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                        <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                                {this.props.columns[isPrivateMode].label}
                            </Dashboard.HeaderText>
                            <Dashboard.HeaderText>
                                {this.props.columns[geofenceName].label}
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                    </Dashboard.SummaryHeaderContainer>
                    <Dashboard.Split columns={columnSize} color={colors.black} height={'3px'} />
                    {this.props.tableFooter &&
                        this.props.totalItemsCount &&
                        this.renderFooterItem(columnSize, showExtraColumns, this.props.tableFooter)}
                    {this.state.showViewMore ? this.renderShowMoreUsers() : null}
                </Dashboard>
            ) : (
                <Dashboard loading={this.props.loading}>
                    <Dashboard.HeaderContainer columns={columnSize}>
                        <Dashboard.HeaderItem
                            key={this.props.columns[startTime].key}
                            onClick={
                                this.props.onClickHeaderItem &&
                                this.props.onClickHeaderItem(this.props.columns[startTime].key, null)
                            }
                        >
                            <Dashboard.HeaderText>
                                {this.props.columns[startTime].label}{' '}
                                <FormattedMessage id={'containers.Reports.Generators.move.To'} />{' '}
                                {this.props.columns[endTime].label}
                            </Dashboard.HeaderText>
                            {this.props.showHeaderCaret && (
                                <Dashboard.HeaderCaret
                                    src={this.getHeaderCaret(this.props.columns[startTime].key)}
                                />
                            )}
                        </Dashboard.HeaderItem>
                        <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                                {this.props.columns[utilisationTime].label}
                                <br />
                                {!showExtraColumns && this.props.columns[stopTime].label}
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                        <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                                {this.props.columns[totalTimeIdle].label}
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                        <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                            {this.props.columns[startAddress].label}
                            <br/>
                                {this.props.columns[stopAddress].label}
                        
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                        <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                                {this.props.columns[distance].label} /{' '}
                                {this.props.columns[driverName].label}
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                        {showExtraColumns && (
                            <Dashboard.HeaderItem>
                                <Dashboard.HeaderText>
                                    {this.props.columns[transitTime].label}
                                    <br />
                                    {this.props.columns[distanceTarget].label}
                                </Dashboard.HeaderText>
                            </Dashboard.HeaderItem>
                        )}
                        {hasGasComsumtion ?
                            <Dashboard.HeaderItem>
                                <Dashboard.HeaderText>
                                    <FormattedMessage id={'containers.Reports.Generators.move.Fuel'} />{' '}
                                </Dashboard.HeaderText>
                            </Dashboard.HeaderItem> 
                            : null}
                            <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                                {this.props.columns[isPrivateMode].label}
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                            <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                            {this.props.columns[odometer].label}<br />
                            {this.props.columns[horometer].label}
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
                         <Dashboard.HeaderItem>
                            <Dashboard.HeaderText>
                                {this.props.columns[geofenceName].label}
                            </Dashboard.HeaderText>
                        </Dashboard.HeaderItem>
            
               
                    </Dashboard.HeaderContainer>
                    <Dashboard.Split columns={columnSize} color={colors.black} height={'3px'} />
                    {this.renderTableData(showExtraColumns)}
                    <Dashboard.Split columns={columnSize} color={colors.black} height={'3px'} />
                    {this.props.tableFooter &&
                        this.props.totalItemsCount &&
                        this.renderFooterItem(columnSize, showExtraColumns, this.props.tableFooter)}
                    {this.state.showViewMore ? this.renderShowMoreUsers() : null}
                </Dashboard>)
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    locale: selectLocale(),
    moveReportSummary: selectMoveType(),
});

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(DashboardComponent)
);
